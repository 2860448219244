<template>
	<div class="p-grid crud-demo">
		<div class="vld-parent">
			<loading :active="loading" color="#D9F7C5" loader="bars"/>
		</div>		
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<h4>Cuentas de usuario en uso {{usuariosActivos}} / {{ctx_usuarios}} </h4>
					</template>					
					<template v-slot:right>
						<Button :disabled="usuariosActivos >= ctx_usuarios" label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="usuarios" class="p-datatable-customers" :rowClass="rowClass" v-model:selection="selectedUsuarios" dataKey="id" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['login', 'nombres', 'apellidos', 'email']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} usuarios" :loading="loadingUsuarios">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Usuarios</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<Column field="login" header="Usuario" :sortable="true" headerStyle="width: 10%">
					</Column>
					<Column field="nombres" header="Nombres" :sortable="true" headerStyle="width: 40%">
					</Column>
					<Column field="apellidos" header="Apellidos" :sortable="true">
					</Column>
					<Column field="email" header="E-mail">
					</Column>		
					<Column field="activo" header="Estado" :sortable="true">
						<template #body="slotProps">							
							<div v-if="(slotProps.data.activo=='N')&&(slotProps.data.activacion=='N')" class="inactivo">
								Inactivo
							</div>
							<div v-if="(slotProps.data.activo=='N')&&(slotProps.data.activacion=='S')" class="en-activacion">
								Activación en Proceso
							</div>
							<div v-if="(slotProps.data.activo=='S')" class="activo">
								Activo
							</div>
						</template>					
					</Column>															
					<Column>
						<template #body="slotProps">							
							<Button v-tooltip="'Enviar email de activación'" icon="pi pi-check-circle" class="p-button-rounded p-button-success p-mr-2" @click="confirmarActivacion(slotProps.data)" />
							<Button v-tooltip="'Permisos & Accesos'" icon="pi pi-key" class="p-button-rounded p-button-info p-mr-2" @click="editPermisos(slotProps.data)" />
							<Button v-tooltip="'Remover'" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="usuarioDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<template #header>
					<h3>Usuario</h3>
					</template>
					<!-- <Card>
						<template #content>
							<div class="p-fluid">
								<div class="p-field">
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
										<InputText v-model="v$.form.email.$model" placeholder="email" :class="{'p-invalid': v$.form.email.$error}"/>
									</div>
									<div class="p-error" v-if="v$.form.email.email.$invalid">La dirección de email es inválida</div>
								</div>									
							</div>					
						</template>
					</Card>					 -->
					<Card>
						<template #content>
							<div class="p-fluid">
								<div class="p-field">
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
										<InputText @blur="validarEmail" v-model="v$.form.email.$model" placeholder="email" :class="{'p-invalid': v$.form.email.$error}"/>
									</div>
									<div class="p-error" v-if="v$.form.email.email.$invalid">La dirección de email es inválida</div>
								</div>									
								<div class="p-field">
									<div class="p-inputgroup">
										<span class="p-inputgroup-addon"><i class="pi pi-id-card"></i></span>
										<InputText :disabled="emailExiste" v-model="v$.form.nombres.$model" placeholder="Nombres" :class="{'p-invalid': v$.form.nombres.$error}"/>
									</div>	
									<div v-for="(error, index) of v$.form.nombres.$errors" :key="index">
										<div class="p-error">{{ error.$message }}</div>
									</div>		
								</div>
								<div class="p-field">
									<div class="p-inputgroup">
									<span class="p-inputgroup-addon"><i class="pi pi-id-card"></i></span>
										<InputText :disabled="emailExiste" v-model="v$.form.apellidos.$model" placeholder="Apellidos" :class="{'p-invalid': v$.form.apellidos.$error}"/>
									</div>							
									<div v-for="(error, index) of v$.form.apellidos.$errors" :key="index">
										<div class="p-error">{{ error.$message }}</div>
									</div>							
								</div>						
								<div class="p-field">
									<div class="p-inputgroup">
									<span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
										<InputText @blur="validarLogin" :disabled="emailExiste" v-model="v$.form.login.$model"  placeholder="Usuario" :class="{'p-invalid': v$.form.login.$error}"/>
										<div v-if="loginExiste" class="p-error">El usuario ya existe! Por favor utilizar identificador</div>
									</div>
									<div v-for="(error, index) of v$.form.login.$errors" :key="index">
										<div class="p-error">{{ error.$message }}</div>
									</div>														
								</div>
								<Message v-for="msg of messages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
							</div>					
						</template>
					</Card>					
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="usuarioDialog = false"/>
						<Button label="Aceptar" icon="pi pi-check" class="p-button-text" @click="registrarUsuario" />
					</template>
				</Dialog>

				<Dialog v-model:visible="activacionUsuarioDialog" :style="{width: '450px'}" header="Confirmar Activación" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="usuario">Esta seguro que desea enviar una activación al usuario seleccionado?<br><b>[{{ usuario.login }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="activacionUsuarioDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="generarActivacion" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteUsuarioDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="usuario">Esta seguro que desea eliminar al usuario seleccionado?<br><b>[{{ usuario.login }}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUsuarioDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteUsuario" />
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import useVuelidate from  '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';	
import UserService from '../service/user.service';
import AuthService from '../service/auth.service';
import { APPNAME } from '../service/constants';
import {FilterMatchMode} from 'primevue/api';

export function validName(name) {
	let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
	
	if (validNamePattern.test(name)){
		return true;
	}
	
	return false;
}

export default {
	setup() { 
		return { v$: useVuelidate() };		
	},		
	validations () {
		return {

			form: {
				nombres: {required, name_validation: {
					$validator: validName,
					$message: 'Nombre inválido. Los nombres válidos solo pueden contener letras, espacios y guiones (-)'
					}
				},
				apellidos : {required, name_validation: {
					$validator: validName,
					$message: 'Apellidos inválido. Los apellidos válidos solo pueden contener letras, espacios y guiones (-)'
					}
				},
				email: {required, email},
				login: {required, min: minLength(6)},
				// passKey: {required, min: minLength(8)},
				// confirmacion: {
				// 	sameAsPasskey: sameAs(this.form.passKey),
				// }		
			}
		}			
	},				
	data() {
		return {
			ctx_usuarios: this.$store.state.auth.currentAppCtx.usuarios,
			usuarios: null,
			usuarioDialog: false,
			deleteUsuarioDialog: false,
			activacionUsuarioDialog: false,
			appName: APPNAME,	
			messages: [],
			usuario: {},		
			selectedUsuarios: null,
			loading: false,
			loadingUsuarios: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			submitted: false,
			loginExiste: false,
			emailExiste: true,
			form: {
				id: 0,
				nombres: '',
				apellidos: '',
				email: '',
				login: '',
				passKey: '',
				confirmacion: ''
			}				
		}
	},
	components:
	{
		Loading
	},
	mounted() {
		this.loadingUsuarios = true;

		UserService.getLoginsXContextos(this.$store.state.auth.currentAppCtx).then(
			data => {
				this.usuarios = data;
				this.loadingUsuarios = false;
			}
		).catch(
							data => {
				this.loadloadingUsuariosing = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}
		)		
	},
	computed: {
		usuariosActivos()
		{
			if(this.usuarios!= null) {
				return this.usuarios.filter(u => (u.activo=='S'||u.activacion=='N')).length;			
			} else {
				return 0;
			}
		}
	},
	methods: {
		rowClass(data) {
			return data.activo === 'N' ? 'row-inactiva': null;
		},		
		openNew() {
			this.loginExiste = true;
			this.form.id = 0;
			this.form.nombres = '';
			this.form.apellidos = '';
			this.form.email = '';
			this.form.login = '';	
			this.usuarioDialog = true;
		},
		editPermisos(usuario) {			
			this.$router.push({name: 'permisos', params: {id: usuario.login}});		
		},
		confirmarActivacion(usuario) {
			this.usuario = usuario;
			this.activacionUsuarioDialog = true;			
		},
		generarActivacion() {			
			this.loading = true;
			var usuario = this.usuario;
			UserService.generarActivacion(this.$store.state.auth.currentAppCtx.codigo, usuario).then(()=> {
				this.loading = false;
				usuario.activo = 'N';
				usuario.activacion = 'S';
			}).catch((error)=>{
				this.loading = false;
				this.messages.push(
				{severity: 'error', content: `${error.error}`}						
				);
			});		
		},
		confirmDelete(usuario) {
			this.usuario = usuario;
			this.deleteUsuarioDialog = true;
		},
		validarEmail() {
			this.loading = true;
			UserService.getLoginXEmail(this.form.email).then((response)=> {
				this.loading = false;
				this.emailExiste = (response.id_login != 0);				
				this.form.nombres = response.nombres;
				this.form.apellidos = response.apellidos;	
				this.form.id = response.id_login;
				this.form.login = response.login;		
			}).catch((error)=>{
				this.loading = false;
				this.messages.push(
				{severity: 'error', content: `${error.error}`}						
				);
			});					
		},
		validarLogin() {
			this.loading = true;
			UserService.getLoginXUsuario(this.form.login).then((response)=> {
				this.loading = false;
				this.loginExiste = (response.id_login != 0);				
			}).catch((error)=>{
				this.loading = false;
				this.messages.push(
				{severity: 'error', content: `${error.error}`}						
				);
			});					
		},		
		registrarUsuario() {
			let login = {
				id_login: this.form.id,
				login: this.form.login,
				passKey: this.form.passKey,
				nombres: this.form.nombres,
				apellidos: this.form.apellidos,
				email: this.form.email
			};
			
			this.loading = true;
			this.messages = [];				
			AuthService.registroCtx(this.$store.state.auth.currentAppCtx.codigo, login).then((response)=> {
				this.loading = false;
				login.id = response.id_login;
				login.activo = 'N';
				login.activacion = 'N';				
				this.usuarios.push(login);
				this.usuarioDialog = false;
			}).catch((error)=>{
				this.loading = false;
				this.messages.push(
				{severity: 'error', content: `${error.error}`}						
				);
			});
		},
		deleteUsuario() {
			UserService.eliminarUsuario(this.$store.state.auth.currentAppCtx.codigo, this.usuario).then(() => {
					this.usuarios = this.usuarios.filter(val => val.id !== this.usuario.id);
					this.deleteUsuarioDialog = false;
					this.usuario = {};
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'El usuario ha sido eliminado!', life: 3000})
			}
			).catch(
				data => {
					this.deleteUsuarioDialog = false;
					this.product = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		}
	}
}
</script>

<style scoped lang="scss">

.activo {
  color: #256029;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: auto;  
}

.en-activacion{
  color: #2e2560;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: auto;  
}

.inactivo{
  color:black;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: auto;  
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-inactiva) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>
